<script lang="ts" setup>
import { Timestamp } from "firebase/firestore";
import { VueFinalModal } from "vue-final-modal";
import { type FeedbackAttachment } from "~/types/Attachment";
import {
  ReportedFeedbackUrgencies,
  ReportedFeedbackUrgency,
} from "~/types/enums/ReportedFeedbackUrgency";
import { type ReportedFeedback } from "~/types/ReportedFeedback";

const props = defineProps({
  type: {
    type: String as () => "problem" | "feedback",
    default: "problem",
  },
});

const emit = defineEmits(["close"]);

const isReporting = ref(false);
const reportProblem = async () => {
  isReporting.value = true;

  await useReportProblem().reportProblem(problem.value, attachments.value);
  isReporting.value = false;
  useBaseToast("Problem reported.", "success");
  emit("close");
};

const problem = ref<ReportedFeedback>({
  urgency: ReportedFeedbackUrgency.low,
  type: props.type,
  description: "",
  userId: useCurrentUID()!,
  userEmail: useCurrentUserEmail()!,
  createdAtTimestamp: Timestamp.now(),
  url: window.location.href,
});

const isCapturingScreenshot = ref(false);

const captureScreenShot = () => {
  isCapturingScreenshot.value = true;
};

const onCloseScreenshotCapture = () => {
  isCapturingScreenshot.value = false;
};

const attachments = ref<FeedbackAttachment[]>([]);

const onCaptureScreenshot = async (screenshot: string) => {
  attachments.value = [
    ...attachments.value,
    { type: "image", data: screenshot },
  ];

  isCapturingScreenshot.value = false;
};

const onRemoveAttachment = (index: number) => {
  attachments.value = attachments.value.filter((_, i) => i != index);
};

const chooseFile = () => {
  fileRef.value?.click();
};

const onFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const files = target.files;

  if (files) {
    const file = files[0];
    attachments.value = [
      ...attachments.value,
      { type: "file", data: file, name: file.name },
    ];
  }
};

const fileRef = ref<HTMLInputElement | null>(null);
</script>

<template>
  <VueFinalModal
    class="problem-modal"
    :content-class="
      isCapturingScreenshot
        ? 'screenshot-modal-content'
        : 'problem-modal-content'
    "
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
    :hide-overlay="isCapturingScreenshot"
  >
    <div class="w-full h-full">
      <BaseTakeScreenshot
        v-if="isCapturingScreenshot"
        class="w-full h-full"
        @close="onCloseScreenshotCapture"
        @screenshot-captured="onCaptureScreenshot"
      />
      <NuxtLayout
        v-if="isCapturingScreenshot != true"
        name="dialog"
        :title="props.type == 'problem' ? 'Report Issue' : 'Share Feedback'"
        @close="$emit('close')"
      >
        <div class="bg-surface rounded p-4">
          <BaseFormComponent v-if="props.type == 'problem'">
            <template #label> Urgency </template>
            <BaseSelect
              v-model="problem.urgency"
              :options="ReportedFeedbackUrgencies"
              value-prop="type"
              label="label"
              :can-clear="false"
            />
          </BaseFormComponent>
          <BaseFormComponent>
            <template #label> Description </template>
            <BaseTextArea v-model="problem.description" class="h-[150px]" />
          </BaseFormComponent>
          <div class="flex flex-row items-center mb-4">
            <BaseTextButton @click="captureScreenShot">
              <div class="flex flex-row items-center">
                <icon name="bx:screenshot" />
                <span class="ml-2"> Take Screenshot </span>
              </div>
            </BaseTextButton>
            <span class="mx-2"> | </span>
            <BaseTextButton @click="chooseFile">
              <div class="flex flex-row items-center">
                <icon name="mdi:file" />
                <span class="ml-2"> Attach Files </span>
              </div>
            </BaseTextButton>
            <input
              type="file"
              class="hidden"
              ref="fileRef"
              @change="onFileChange"
            />
          </div>
          <div class="flex flex-row items-start flex-wrap">
            <ReportProblemAttachments
              :attachments="attachments"
              @remove-attachment="onRemoveAttachment"
            />
          </div>

          <div class="flex flex-row justify-end">
            <BaseTextButton color="danger" @click="$emit('close')" class="mr-2">
              Cancel
            </BaseTextButton>
            <BaseButton @click="reportProblem" :show-spinner="isReporting">
              {{ props.type == "problem" ? "Report Issue" : "Submit Feedback" }}
            </BaseButton>
          </div>
        </div>
      </NuxtLayout>
    </div>
  </VueFinalModal>
</template>

<style>
.problem-modal {
  z-index: 9999 !important;
}

.screenshot-modal-content {
  width: 100%;
  height: 100%;
}

.problem-modal-content {
  display: flex;
  flex-direction: column;

  width: 700px;
  max-height: calc(100% - 64px);
  overflow: auto;

  margin: auto;
  @apply rounded;
  margin-top: 32px;
}
</style>
